
import React, { useRef, useState } from 'react'
import { useGLTF } from '@react-three/drei'
import {useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import $ from "jquery";
export function Model(props) {
  const { nodes, materials } = useGLTF('./models/rosary.glb')
  
  const Modelref = useRef()
  const [hovered, setHovered] = useState(false)
  const [active, setActive] = useState(false);
  const vec = new THREE.Vector3()

  let link_adress =$('#link_adress').val();
  
  const click_material = new THREE.MeshStandardMaterial({
    color: 0xf7f2ba,
    transparent: true, opacity: 0.9
});
 

  useFrame((state) => {
    
    
    if (hovered) {
      Modelref.current.rotation.z += 0.0

    } else {
     Modelref.current.rotation.z += 0.005

    }
  })

  const clickBall = (e) => {
    e.stopPropagation()

    
    let ball_id = e.object.name;

    if(ball_id === 'C_J' || ball_id === 'M_J'){} else {
      e.object.material = click_material;
    }
    
    
    $.post(link_adress, {ball: ball_id}, function(data){
      
      $('#modal_div').html(data);

      
    });

    $('#modal_div').removeClass('div_hidden');
  }



  return (
    <group {...props} ref={Modelref} dispose={null} position={[0.1, 2.4, -1]} scale={[7.5,7.5,7.5]} rotation={[1.5,0,0]}
    onPointerOut={(e) => setHovered (false)}
    onPointerOver={(e) => setHovered (true)}>
      <group position={[0, 0, 0.495]} rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='MD_2' geometry={nodes.MD_2_1.geometry} material={materials.Pearl} onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.MD_2_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2} >
        <mesh name='DLL' geometry={nodes.D5_10_1.geometry} material={materials.Pearl} onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D5_10_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D5_9_1.geometry} material={materials.Pearl}   onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D5_9_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D5_8_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D5_8_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D5_7_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D5_7_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D5_6_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D5_6_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D5_5_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D5_5_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D5_4_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D5_4_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D5_3_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D5_3_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D5_2_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D5_2_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D5_1_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D5_1_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='M4' geometry={nodes.M4_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.M4_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='DL' geometry={nodes.D4_10_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D4_10_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D4_9_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D4_9_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D4_8_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D4_8_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D4_7_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D4_7_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D4_6_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D4_6_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D4_5_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D4_5_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D4_4_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D4_4_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D4_3_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D4_3_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D4_2_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D4_2_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D4_1_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D4_1_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='M3' geometry={nodes.M3_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.M3_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='DL' geometry={nodes.D3_10_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D3_10_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D3_9_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D3_9_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D3_8_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D3_8_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D3_7_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D3_7_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D3_6_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D3_6_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D3_5_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D3_5_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D3_4_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D3_4_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D3_3_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D3_3_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D3_2_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D3_2_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D3_1_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D3_1_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='M2' geometry={nodes.M2_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.M2_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='DL' geometry={nodes.D2_10_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D2_10_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D2_9_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D2_9_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D2_8_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D2_8_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D2_7_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D2_7_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D2_6_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D2_6_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D2_5_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D2_5_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D2_4_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D2_4_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D2_3_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D2_3_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D2_2_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D2_2_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D2_1_1.geometry} material={materials.Pearl}  onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D2_1_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='M1' geometry={nodes.M1_1.geometry} material={materials.Pearl} onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.M1_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='DL' geometry={nodes.D1_10_1.geometry} material={materials.Pearl} onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D1_10_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D1_9_1.geometry} material={materials.Pearl} onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D1_9_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D1_8_1.geometry} material={materials.Pearl} onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D1_8_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D1_7_1.geometry} material={materials.Pearl} onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D1_7_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D1_6_1.geometry} material={materials.Pearl} onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D1_6_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D1_5_1.geometry} material={materials.Pearl} onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D1_5_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D1_4_1.geometry} material={materials.Pearl} onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D1_4_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D1_3_1.geometry} material={materials.Pearl} onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D1_3_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D1_2_1.geometry} material={materials.Pearl} onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D1_2_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='D' geometry={nodes.D1_1_1.geometry} material={materials.Pearl} onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.D1_1_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group position={[0, 0, 0.54]} rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='DD_3' geometry={nodes.DD_3_1.geometry} material={materials.Pearl} onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.DD_3_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group position={[0, 0, 0.562]} rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='DD_2' geometry={nodes.DD_2_1.geometry} material={materials.Pearl} onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.DD_2_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group position={[0, 0, 0.585]} rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='DD_1' geometry={nodes.DD_1_1.geometry} material={materials.Pearl} onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.DD_1_2.geometry} material={materials.Metal_Inside} />
      </group>
      <group position={[0, 0, 0.63]} rotation={[-Math.PI, 0, 0]} scale={2}>
        <mesh name='MD_1' geometry={nodes.MD_1_1.geometry} material={materials.Pearl} onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.MD_1_2.geometry} material={materials.Metal_Inside} />
      </group>
      <mesh geometry={nodes.MAIN_STRING.geometry} material={materials.String} />
      <mesh geometry={nodes.STRING_DOWN.geometry} material={materials.String} position={[0, 0, 0.562]} rotation={[Math.PI / 2, 0, 0]} scale={[0.788, 9.133, 0.788]} />
      <mesh geometry={nodes.CB_5.geometry} material={materials['Ring_Join.001']} position={[0.111, 0, -0.149]} />
      <mesh geometry={nodes.CB_4.geometry} material={materials['Ring_Join.001']} position={[0.14, 0, -0.115]} />
      <mesh geometry={nodes.CB_3.geometry} material={materials['Ring_Join.001']} position={[0.182, 0, 0.122]} />
      <mesh geometry={nodes.CB_2.geometry} material={materials['Ring_Join.001']} position={[0.17, 0, 0.165]} />
      <mesh geometry={nodes.CB_9.geometry} material={materials['Ring_Join.001']} position={[-0.171, 0, 0.164]} />
      <mesh geometry={nodes.CB_8.geometry} material={materials['Ring_Join.001']} position={[-0.182, 0, 0.121]} />
      <mesh geometry={nodes.CB_7.geometry} material={materials['Ring_Join.001']} position={[-0.14, 0, -0.115]} />
      <mesh geometry={nodes.CB_6.geometry} material={materials['Ring_Join.001']} position={[-0.11, 0, -0.149]} />
      <mesh geometry={nodes.CBD_3.geometry} material={materials['Ring_Join.001']} position={[0, 0, 0.518]} rotation={[0, 0.321, 0]} />
      <mesh geometry={nodes.CBD_2.geometry} material={materials['Ring_Join.001']} position={[0, 0, 0.608]} rotation={[0, 0.321, 0]} />
      <group position={[0.031, 0, 0.377]} rotation={[Math.PI / 2, 0, -0.712]} scale={[0.405, 0.643, 0.405]}>
        <mesh geometry={nodes.CB_1_1.geometry} material={materials['Ring_Join.001']} />
        <mesh geometry={nodes.CB_1_2.geometry} material={materials['Ring_Join.001']} />
      </group>
      <group position={[-0.031, 0, 0.377]} rotation={[Math.PI / 2, 0, -2.496]} scale={[0.405, 0.643, 0.405]}>
        <mesh geometry={nodes.CB_10_1.geometry} material={materials['Ring_Join.001']} />
        <mesh geometry={nodes.CB_10_2.geometry} material={materials['Ring_Join.001']} />
      </group>
      <group position={[0, 0, 0.665]} rotation={[Math.PI / 2, 0, -1.588]} scale={[0.405, 0.643, 0.405]}>
        <mesh geometry={nodes.CBD_1_1.geometry} material={materials['Ring_Join.001']} />
        <mesh geometry={nodes.CBD_1_2.geometry} material={materials['Ring_Join.001']} />
      </group>
      <group position={[0, 0, 0.462]} rotation={[Math.PI / 2, 0, 1.554]} scale={[0.405, 0.643, 0.405]}>
        <mesh geometry={nodes.CBD_4_1.geometry} material={materials['Ring_Join.001']} />
        <mesh geometry={nodes.CBD_4_2.geometry} material={materials['Ring_Join.001']} />
      </group>
      <mesh name='C_J' geometry={nodes.JESUS.geometry} material={materials['Ring_Join.001']} position={[0, 0, 0.694]} scale={[0.406, 0.25, 1]} onPointerUp={(e) => clickBall(e)}/>
      <mesh geometry={nodes.JESUS_JOIN.geometry} material={materials['Ring_Join.001']} position={[0, 0, 0.671]} scale={[0.47, 0.306, 0.657]} />
      <group position={[0, 0, 0.41]} scale={[0.898, 0.471, 1.256]}>
        <mesh name='M_J' geometry={nodes.MAIN_JOIN_1.geometry} material={materials.Main_Join} onPointerUp={(e) => clickBall(e)}/>
        <mesh geometry={nodes.MAIN_JOIN_2.geometry} material={materials['Ring_Join.001']} />
      </group>
    </group>
  )
}

useGLTF.preload('./models/rosary.glb')
