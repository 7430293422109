import "./App.css";
import { Canvas } from '@react-three/fiber';
import { Environment} from "@react-three/drei";
import { Model } from "./Rosary";
import './App.css';







function App() {
  

  return (

    
    <div className="canvas-wrap">
      <Canvas >
        <ambientLight args={[0xffffff]} intensity={0.8} />
        <directionalLight position={[3, 0.0, 5]} color={0xffe1a1} intensity={0.5} />
        <directionalLight position={[0, 0.0, 5]} color={0xffe1a1} intensity={0.3} />
        <directionalLight position={[-3, 0.0, 5]} color={0xffe1a1} intensity={0.5} />
        <Environment files="textures/brdo_1.hdr"  />
        <Model />
      </Canvas>
    </div>
  );
}

export default App;
